import { Box, Button, Image, Link, Spacer, Stack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import { SupportedLocaleKey } from 'modules/i18n/constants'
import { useUserContext } from 'modules/user'
import gammaLogo from 'publicImages/gamma-logo-white.svg'
import { isMobileDevice } from 'utils/deviceDetection'

import { MARKETING_HEADER_HEIGHT, MARKETING_SITE_LINKS } from '../constants'
import { getLocalizedMarketingSiteLinks } from '../utils/urls'

const getHeaderLinks = (locale) => [
  {
    text: <Trans>Pricing</Trans>,
    url: getLocalizedMarketingSiteLinks(locale).pricing,
    hideWhenSmall: true,
  },
  {
    text: <Trans>Templates</Trans>,
    url: MARKETING_SITE_LINKS.templates,
    hideWhenSmall: true,
  },
  {
    text: <Trans>Login</Trans>,
    url: MARKETING_SITE_LINKS.login,
  },
]

export type HeaderMode = 'dark' | 'light'

export const MarketingHeader = ({
  headerMode = 'light',
  fixed = false,
}: {
  headerMode?: HeaderMode
  fixed?: boolean
}) => {
  const { user, isUserLoading } = useUserContext()
  const { query } = useRouter()

  const { locale } = query
  const showNav = !isUserLoading && !user
  const isLight = headerMode === 'light'
  const shouldUseFixedHeader = fixed && !isMobileDevice()
  const HEADER_LINKS = getHeaderLinks(locale)
  return (
    <Box
      w="100%"
      py={4}
      px={[6, 6, 12]}
      h={MARKETING_HEADER_HEIGHT}
      pr={[4, 6, 12]}
      zIndex="999"
      top={['auto', 'auto', '0']}
      position={shouldUseFixedHeader ? 'fixed' : 'relative'}
      backdropFilter={shouldUseFixedHeader ? 'blur(20px)' : 'none'}
      backgroundColor={
        shouldUseFixedHeader
          ? isLight
            ? 'whiteAlpha.400'
            : 'blackAlpha.400'
          : 'transparent'
      }
    >
      <Stack direction="row">
        <Link
          href={
            getLocalizedMarketingSiteLinks(locale as SupportedLocaleKey).home
          }
          aria-current="page"
          as={NextLink}
        >
          <Image
            w="100px"
            src={
              isLight
                ? 'https://assets.website-files.com/62e823626cd4fdd0e5dc1527/62e823626cd4fdc26bdc1576_Gamma.svg'
                : gammaLogo.src
            }
            loading="lazy"
            alt="Gamma logo"
          />
        </Link>
        <Spacer />
        {showNav && (
          <Stack
            direction="row"
            spacing={[4, 4, 8]}
            fontWeight="500"
            fontSize="14px"
            color={isLight ? '#3b3a40' : '#fff'}
            alignItems="center"
            transition="color 300ms ease"
          >
            {HEADER_LINKS.map(({ text, url, hideWhenSmall }, ind) => {
              return (
                <Link
                  as={NextLink}
                  key={ind}
                  display={hideWhenSmall ? ['none', 'none', 'block'] : 'block'}
                  href={url}
                  color={isLight ? undefined : 'whiteAlpha.800'}
                  _hover={{
                    color: isLight ? '#000' : 'whiteAlpha.900',
                    textDecoration: 'none',
                  }}
                >
                  {text}
                </Link>
              )
            })}
            <Button
              as={Link}
              href={MARKETING_SITE_LINKS.signup}
              maxHeight="32px"
              borderStyle="solid"
              borderWidth="0.5px"
              fontSize="14px"
              borderColor="rgba(0, 0, 0, 0.24)"
              backgroundColor="#fff"
              boxShadow="0 4px 4px 0 rgb(0 0 0 / 5%)"
              fontWeight="700"
              borderRadius="100px"
              color="#3b3a40"
              _hover={{
                color: 'black',
                textDecoration: 'none',
              }}
            >
              <Trans>Try for free</Trans>
            </Button>
          </Stack>
        )}
      </Stack>
    </Box>
  )
}
