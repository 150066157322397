export const isPersonalEmail = (email: string) => {
  return (
    email.endsWith('gmail.com') ||
    email.endsWith('hotmail.com') ||
    email.endsWith('.edu')
  )
}

export const LOGGED_IN_COOKIE_NAME = 'gamma_logged_in'
const TEN_YEARS_IN_MS = 10 * 365 * 86400 * 1000 // session expires in 10 years

export const LOGGED_IN_COOKIE_MAX_AGE = TEN_YEARS_IN_MS
