import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  ListItem,
  SimpleGrid,
  Stack,
  UnorderedList,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import NextLink from 'next/link'
/**
 * NB: This should mirror the footer on the Marketing site. That's why the fonts are specified
 * in px units
 */

import { useRouter } from 'next/router'

import { SupportedLocaleKey } from 'modules/i18n/constants'
import { useLaunchIntercom } from 'modules/intercom/hooks'

import { MARKETING_SITE_LINKS } from '../constants'
import { getLocalizedMarketingSiteLinks } from '../utils/urls'

const getFooterLinks = (handleContactUsClick, locale) => {
  return [
    {
      category: <Trans>Product</Trans>,
      items: [
        {
          text: <Trans>Help</Trans>,
          url: MARKETING_SITE_LINKS.help,
        },
        {
          text: <Trans>Change log</Trans>,
          url: MARKETING_SITE_LINKS.changeLog,
        },
        {
          text: <Trans>Pricing</Trans>,
          url: getLocalizedMarketingSiteLinks(locale as SupportedLocaleKey)
            .pricing,
        },
        {
          text: <Trans>Templates</Trans>,
          url: MARKETING_SITE_LINKS.templates,
        },
        {
          text: <Trans>Inspiration</Trans>,
          url: MARKETING_SITE_LINKS.inspiration,
        },
        {
          text: <Trans>Contact us</Trans>,
          onClick: handleContactUsClick,
        },
      ],
    },
    {
      category: <Trans>Company</Trans>,
      items: [
        {
          text: <Trans>Careers</Trans>,
          url: MARKETING_SITE_LINKS.careers,
        },
        {
          text: <Trans>Team</Trans>,
          url: MARKETING_SITE_LINKS.team,
        },
        {
          text: <Trans>Terms of Service</Trans>,
          url: MARKETING_SITE_LINKS.terms,
        },
        {
          text: <Trans>Privacy Policy</Trans>,
          url: MARKETING_SITE_LINKS.privacy,
        },
      ],
    },
    {
      category: <Trans>Follow us</Trans>,
      items: [
        {
          text: <Trans>Twitter</Trans>,
          url: 'https://twitter.com/meetgamma',
        },
        {
          text: <Trans>Instagram</Trans>,
          url: 'https://www.instagram.com/meetgamma/',
        },
        {
          text: <Trans>LinkedIn</Trans>,
          url: 'https://www.linkedin.com/company/gamma-app/',
        },
        {
          text: <Trans>TikTok</Trans>,
          url: 'https://www.tiktok.com/@meetgamma',
        },
      ],
    },
  ]
}
export const MarketingFooter = () => {
  const launchIntercomUI = useLaunchIntercom()
  const { query } = useRouter()
  const { locale } = query
  const footerLinks = getFooterLinks(launchIntercomUI, locale)
  return (
    <Flex
      fontSize="12px"
      bgColor="#1d0042"
      color="gray.400"
      py={20}
      id="footer"
      justifyContent="center"
    >
      <Container maxW="940px">
        <Stack>
          <SimpleGrid columns={4} spacing={6} mb={10} minChildWidth="200px">
            <Box w="100%">
              <Link
                href={
                  getLocalizedMarketingSiteLinks(locale as SupportedLocaleKey)
                    .home
                }
              >
                <Image
                  src="https://assets.website-files.com/60de2701a7b28f308f619d3d/60de2701a7b28ff7e9619d49_White%20Logo%20g%20spot.svg"
                  loading="lazy"
                  width="120px"
                  alt="Gamma logo"
                />
              </Link>
            </Box>
            {footerLinks.map(({ category, items }, index) => {
              return (
                <Box w="100%" key={index}>
                  <Heading
                    marginBottom="25px"
                    color="rgba(239, 208, 255, 0.7)"
                    fontWeight="bold"
                    fontSize="14px"
                    fontFamily="var(--body-font)"
                    letterSpacing="normal"
                  >
                    {category}
                  </Heading>
                  <UnorderedList listStyleType="none" margin={0}>
                    {items.map(({ text, url, onClick }, index) => {
                      return (
                        <ListItem fontSize="14px" key={index} pb={4}>
                          <Link
                            as={onClick ? undefined : NextLink}
                            color="hsla(0, 0%, 100%, 0.86)"
                            href={url ? url : undefined}
                            onClick={onClick ? onClick : undefined}
                          >
                            {text}
                          </Link>
                        </ListItem>
                      )
                    })}
                  </UnorderedList>
                </Box>
              )
            })}
          </SimpleGrid>
          <Center color="rgba(239, 208, 255, 0.7)" fontSize="13px">
            &copy; {new Date().getFullYear()} Gamma Tech, Inc.
          </Center>
        </Stack>
      </Container>
    </Flex>
  )
}
