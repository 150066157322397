import {
  SUPPORTED_LOCALE_KEYS,
  SupportedLocaleKey,
} from 'modules/i18n/constants'

export const getLocalizedMarketingSiteLinks = (locale?: SupportedLocaleKey) => {
  if (!locale || !SUPPORTED_LOCALE_KEYS.includes(locale)) {
    return {
      home: '/',
      pricing: '/pricing',
    }
  }
  return {
    home: `/${locale}`,
    pricing: `/${locale}/pricing`,
  }
}
