import jsCookie from 'js-cookie'
import { useEffect } from 'react'

import { config } from 'config'
import { useUserContext } from 'modules/user'
import {
  LOGGED_IN_COOKIE_MAX_AGE,
  LOGGED_IN_COOKIE_NAME,
} from 'modules/user/utils'

export const useSetLoggedInCookieForCustomVersions = () => {
  const { user, isUserLoading } = useUserContext()

  // Set a logged in cookie for vercel custom versions and yarn dev:remote
  const isStagingAPIHost = config.API_HOST.endsWith('api.staging.gamma.app')
  const isLocalOrCustomVersionURL =
    typeof window !== 'undefined' &&
    (window.location.hostname.match(/localhost/) !== null ||
      window.location.hostname.match(/\.*-gamma-app.vercel.app/) !== null)

  useEffect(() => {
    if (!isStagingAPIHost || !isLocalOrCustomVersionURL) {
      return
    }
    if (isUserLoading || !user || jsCookie.get(LOGGED_IN_COOKIE_NAME)) {
      return
    }

    // Set the logged in cookie since we have confirmed that we're logged in and reload
    // as we should see the dashboard not the marketing page
    jsCookie.set(LOGGED_IN_COOKIE_NAME, 'true', {
      expires: LOGGED_IN_COOKIE_MAX_AGE,
      secure: true,
      httpOnly: false,
    })
    window.location.reload()
  }, [isUserLoading, user, isStagingAPIHost, isLocalOrCustomVersionURL])
}
